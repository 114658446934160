.covid-banner {
  background-color: $covid-bg;
  padding-top: 45px;
  padding-bottom: 45px;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.covid-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
  width: 900px;
  text-align: center;
}