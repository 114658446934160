.content-middle {
  margin-left: auto !important;
  margin-right: auto !important;
}

.content-middle-vert {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content-flexbox {
  //display: flex;
  display:block;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content-squeeze {
  max-width: 80% !important;
  width: 700px !important;
}

.content-max {
  max-width: 100% !important;
}

.content-left {
  float: left !important;
  width: 40% !important;
}

.content-right {
  width: 60%;
  float: right;
}
