@import "alignment";
@import "global";
@import "covid";

a {
  text-decoration: none;
  color: white;
}

@font-face {
  font-family: 'ARS Maquette Pro';
  src: local('ARS Maquette Pro Regular'), local('ARS-Maquette-Pro-Regular'),
  url('ARSMaquettePro-Regular.woff2') format('woff2'),
  url('ARSMaquettePro-Regular.woff') format('woff'),
  url('ARSMaquettePro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

// default values:
body {
  //font-family: "ARS Maquette Pro", -apple-system, BlinkMacSystemFont,
  //'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  //'Droid Sans', 'Helvetica Neue', sans-serif;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

